import axios from "axios"
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Button, Table} from 'react-bootstrap';

export default function ListPlayers({auth}) {

    const [players, setPlayers] = useState([]);
    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getUsers() {
        let dataPlayers = null;
        axios.get('https://davecode.stronawcal.pl/kobiety/api/player').then(function (response) {
            dataPlayers = response.data.sort(comparator)
            setPlayers(dataPlayers);
        });
    }

    const deleteUser = (id) => {
        axios.delete(`https://davecode.stronawcal.pl/kobiety/api/player/${id}/delete`).then(function (response) {
            getUsers();
        });
    }

    const comparator = (a, b) => {
        if (Number(a.points) > Number(b.points))
            return -1;
        if (Number(a.points) < Number(b.points))
            return 1;
        return 0;
    }
    const phoneLink = (phone) => {
        return (
            <a className="phone-number" href={`tel:${phone}`}>{phone}</a>

        )
    }

    return (
        <div>
            <div>
                <h4 className="mb-4">Lista zawodników KOBIETY</h4>
                {auth &&
                <Link to="dodaj/zawodnika">Dodaj zawodnika</Link>
                }
            </div>
            <div style={{maxWidth: 1280, margin: 'auto'}}>
                <Table className="container-table" striped bordered hover responsive="lg" size="md">
                    <thead className='header-column'>
                    <tr>
                        <th>Nr.</th>
                        <th>Za pkt.</th>
                        <th>Imię i Nazwisko</th>
                        <th>Punkty</th>
                        <th>+</th>
                        <th>-</th>
                        <th>Mecze</th>
                        <th>Telefon</th>
                        {window.innerWidth > 769 &&
                        <th>Informacja</th>
                        }
                        {auth &&
                        <th>Akcja</th>
                        }

                    </tr>
                    </thead>
                    <tbody>
                    {players.map((player, index) =>
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td className='column-value-player'>{player.value_player}</td>
                            <td>{player.name}</td>
                            <td className='column-points'>{player.points}</td>
                            <td className='column-win'>{player.win}</td>
                            <td className='column-loss'>{player.loss}</td>
                            <td>{player.quantity_game}</td>
                            <td>{phoneLink(player.phone)}</td>
                            {window.innerWidth > 769 &&
                            <td>{player.comments}</td>
                            }
                            {auth &&
                            <td>
                                <Link className="btn-primary" to={`zawodnik/${player.id}/edycja`}
                                      style={{marginRight: "10px"}}>Edytuj</Link>
                                <Button variant="danger" size="sm" onClick={() => deleteUser(player.id)}>Usuń</Button>
                            </td>
                            }
                        </tr>
                    )}
                    </tbody>
                </Table>
                {players.length === 0 && <span className="empty-table">Brak zawodników</span>}
            </div>

        </div>
    )
}

