import {useEffect, useState} from "react";
import axios from "axios";
import {Button, Form, Row, Col} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";

export default function CreateResult() {

    const navigate = useNavigate();
    const [inputs, setInputs] = useState([]);
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        getUsers();
    }, []);


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        if(name === 'player_loss') {
            setInputs(values => ({...values, value_loss: players.filter(item => item.id === value)[0].value_player}));
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://davecode.stronawcal.pl/kobiety/api2/result/save', inputs).then(function (response) {
            if(response.status === 200) {
                navigate('/wyniki');
            }
        })
            .catch(function (error) {
                console.log(error);
        });
    }

    function getUsers() {
        axios.get('https://davecode.stronawcal.pl/kobiety/api/player').then(function(response) {
            setPlayers(response.data);
        });
    }

    return (
        <>
            <h4>Dodaj Wynik</h4>
            <div className="form-result">
                <Form onSubmit={handleSubmit}>

                    <Row className="mb-4">
                        <Form.Label>Zawodnicy:</Form.Label>
                        <Col>
                            <Form.Label>Wygrany:</Form.Label>
                            <Form.Select
                                aria-label="Default select example"
                                name="player_win"
                                placeholder="Przegrany"
                                required
                                onChange={handleChange}>
                                <option>Wybierz zawodnika</option>
                                {
                                    players.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Label>Przegrany:</Form.Label>
                            <Form.Select aria-label="Default select example" name="player_loss" onChange={handleChange}>
                                <option>Wybierz zawodnika</option>
                                {
                                    players.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Form.Label>Gemy w poszczegolnych setach(wygrany-przegrany):</Form.Label>
                        <Col>
                            <Form.Label>Set1:</Form.Label>
                            <div className="flex-center">
                                <Form.Control
                                    name="set_1a"
                                    required
                                    onChange={handleChange}
                                />
                                <Form.Control
                                    name="set_1b"
                                    required
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>
                        <Col>
                            <Form.Label>Set2:</Form.Label>
                            <div className="flex-center">
                                <Form.Control
                                    name="set_2a"
                                    required
                                    onChange={handleChange}
                                />
                                <Form.Control
                                    name="set_2b"
                                    required
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>
                        <Col>
                            <Form.Label>Set3:</Form.Label>
                            <div className="flex-center">
                                <Form.Control name="set_3a" onChange={handleChange}/>
                                <Form.Control name="set_3b" onChange={handleChange}/>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Form.Label>Data spotkania:</Form.Label>
                        <Form.Control
                            name="date_create"
                            type="date"
                            required
                            onChange={handleChange}
                        />

                    </Row>

                    <Row className="mb-4">
                        <Form.Label>Uwagi:</Form.Label>
                        <Form.Control name="comments" as="textarea" rows={3} onChange={handleChange}/>
                    </Row>

                    <Button variant="primary" type="submit">
                        Zapisz
                    </Button>
                </Form>
            </div>

        </>
    )
}

